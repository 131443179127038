import React from "react";
import { Element } from "react-scroll";

import { Box } from "../../components/Core";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/landing5/Hero";
import WorkSingle from "../portfolio-details";
import CTA from "../../sections/landing5/CTA";
import {useDataSet} from "../../hooks/useDataSet";


const IndexPage = (props) => {
  console.log(props)
  const [data, loading] = useDataSet('campaign', props.campaign)
  return (
    <>
    {loading === false ? ( 
      <PageWrapper>
        <Box>
          {/* <Hero /> */}
          <Element name="works">
            <WorkSingle campaign={props.campaign} works={data}/>
          </Element>
          {/* <CTA /> */}
        </Box>
      </PageWrapper>
      ) : (false)}
    </>
  );
};
export default IndexPage;
