import {useState, useEffect} from "react";

// Hook
export const useDataSet = (type = "galleries", campaign = null) => {

    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function getGalleriesData() {
            fetch(`http://localhost:1337/api/galleries?populate=*`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json()).then(data => {
                setData(data)
                setLoading(false)
            }).catch(e => console.log(e))
        }

        async function getCampaignData() {
            fetch(`http://localhost:1337/api/campaigns/${campaign}?populate=*`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json()).then(data => {
                setData(data)
                setLoading(false)
            }).catch(e => console.log(e))
        }

        async function getCategoriesData() {
            fetch(`http://localhost:1337/api/categories?populate=*`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json()).then(data => {
                setData(data.data)
                setLoading(false)
            }).catch(e => console.log(e))
        }

        switch (type) {
            case "categories":
                getCategoriesData();
                break;
            case "campaign":
                getCampaignData(campaign)
                break;
            case "galleries":
            default:
                getGalleriesData()
                break
        }


    }, [])

    return [data, loading];
};
